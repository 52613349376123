/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DataGridLoader from './DataGridLoader';
import DataGridCheckbox from './DataGridCheckbox';
import {
  sortModelToString,
  stringOrderByToSortModel,
} from '../tables/utils/selectionModelHandlers';

const ServerSideDataGrid = ({
  columns,
  apolloQuery: { query, variables, fetchPolicy = 'cache-first' },
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [orderBy, setOrderBy] = useState('-pk');

  const { data, loading } = useQuery(query, {
    variables: {
      ...variables,
      first: pageSize,
      offset: page * pageSize,
      orderBy,
    },
    fetchPolicy,
  });

  const sortModel = stringOrderByToSortModel(orderBy);
  const handleSortModelChange = useCallback(
    (model) => {
      const stringOrder = sortModelToString(model);
      setOrderBy(stringOrder);
    },
    [setOrderBy],
  );

  const rows = useMemo(
    () => Object.values(data || [])[0]?.edges?.map((edge) => edge.node) || [],
    [data],
  );
  const totalCount = useMemo(
    () => Object.values(data || [])[0]?.totalCount || 0,
    [data],
  );

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={loading}
        components={{
          LoadingOverlay: DataGridLoader,
          BaseCheckbox: DataGridCheckbox,
        }}
        rowCount={totalCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[15, 25, 50, 100]}
        {...props}
      />
    </Box>
  );
};

ServerSideDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  apolloQuery: PropTypes.shape({
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    fetchPolicy: PropTypes.string,
  }).isRequired,
  variables: PropTypes.object.isRequired,
};

export default ServerSideDataGrid;
